<template>
    <div class="col-md-12">
      <div class="card card-container">
        <img src="@/assets/logo-ssrs.png" class="img-fluid mb-4">
        <form name="form" @submit.prevent="handleLogin">
            <div class="form-group">
                <label for="cpf">CPF</label>
                <input
                    v-model="user.cpf"
                    v-mask-cpf
                    v-validate="'required'"
                    type="text"
                    class="form-control"
                    name="cpf"
                />
                <div
                    v-if="errors.cpf"
                    class="alert alert-danger"
                    role="alert"
                >
                    CPF é obrigatório!
                </div>
            </div>
            <div class="form-group">
                <label for="password">Senha</label>
                <input
                    v-model="user.password"
                    v-validate="'required'"
                    type="password"
                    class="form-control"
                    name="password"
                />
                <div
                    v-if="errors.password"
                    class="alert alert-danger"
                    role="alert"
                >
                    Senha é obrigatório!
                </div>
            </div>
            <div class="form-group">
                <div class="col-12 d-grid gap-2">
                    <button class="btn btn-primary btn-block mt-4" :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span> Login</span>
                    </button>
                </div>
            </div>
            <div class="form-group">
                <div v-if="message" class="alert alert-danger mt-3" role="alert">{{message}}</div>
            </div>
            <div class="form-group">
                <div class="col-12 d-grid gap-2">
                    <button class="btn btn-success btn-block mt-4" :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span> Ir para telão</span>
                    </button>
                </div>
            </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>

  import User from '../models/user';
  export default {
    name: 'Login',
    data() {
      return {
        user: new User('', ''),
        errors: {
            cpf: false,
            password: false
        },
        loading: false,
        message: null
      };
    },
    computed: {
      loggedIn() {
        return this.$store.state.status.loggedIn;
      }
    },
    created() {
      if (this.loggedIn) {
        this.$router.push((this.$store.state.user.permission == 'full' ? '/admin' : '/menu'));
      }
    },
    methods: {
      handleLogin() {
        this.message = null;
        this.loading = true;
        // this.$validator.validateAll().then(isValid => {
        //   if (!isValid) {
        //     this.loading = false;
        //     return;
        //   }
          if (this.user.cpf && this.user.password) {
            this.$store.dispatch('login', this.user).then(
              r => {
                this.$router.push((r.permission == 'full' ? '/admin' : '/menu'));
              },
              error => {
                this.loading = false;
                this.message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            );
          }
        // });
      }
    }
  };
  </script>
  
  <style scoped>
  label {
    display: block;
    margin-top: 10px;
  }
  .card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
  }
  .card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  }
  .profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  </style>